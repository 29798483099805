var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{attrs:{"role":"main"}},[_c('meta-title',{attrs:{"title":_vm.metaTitle}}),_c('section',{staticClass:"section-first-page-hero"},[_c('slider')],1),_c('section',{staticClass:"section section-first-page-tiles"},[_c('div',{staticClass:"container m-container-only-s"},[_c('div',{staticClass:"tiles"},[_c('div',{staticClass:"tile tile-about top",on:{"click":function($event){$event.preventDefault();return _vm.$router.push({ name: 'Airline' })}}},[_c('div',{staticClass:"tile-container"},[_c('p',{staticClass:"title-pobeda",domProps:{"innerHTML":_vm._s(_vm.$t('pages.main_info'))}}),_c('router-link',{staticClass:"btn",attrs:{"to":{ name: 'Airline' }}},[_c('span',[_vm._v(_vm._s(_vm.$t("pages.main_button")))])])],1)]),_c('div',{staticClass:"tile two-cols-right tile-entertaiments top",on:{"click":function($event){$event.preventDefault();return _vm.$router.push({ name: 'Main' })}}},[_c('div',{staticClass:"tile-container"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('pages.main_ife'))}}),_c('router-link',{staticClass:"btn",attrs:{"to":{ name: 'Main' }}},[_c('span',[_vm._v(_vm._s(_vm.$t("pages.main_button")))])])],1)]),(_vm.paidSection)?_c('div',{staticClass:"tile two-cols-left tile-team bottom",on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
                name: 'MovieList',
                params: {
                  section: _vm.paidSection.id,
                  collection: _vm.paidSection.collections[0].id
                }
              })}}},[_c('div',{staticClass:"tile-container"},[_c('div',{staticClass:"money"}),_c('p',[_vm._v(_vm._s(_vm.$t("pages.best_film")))]),_c('router-link',{staticClass:"btn",attrs:{"to":{
                  name: 'MovieList',
                  params: {
                    section: _vm.paidSection.id,
                    collection: _vm.paidSection.collections[0].id
                  }
                }}},[_vm._v(_vm._s(_vm.$t("pages.main_button")))])],1)]):_c('div',{staticClass:"tile two-cols-left tile-team bottom"},[_c('div',{staticClass:"tile-container"},[_c('div',{staticClass:"money"}),_c('p',[_vm._v(_vm._s(_vm.$t("pages.best_film")))]),_c('a',{staticClass:"btn",attrs:{"href":""}},[_c('span',[_vm._v(_vm._s(_vm.$t("pages.main_button")))])])])]),_c('div',{staticClass:"tile tile-press bottom"})])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }