<template>
  <main role="main">
    <meta-title :title="metaTitle"></meta-title>
    <section class="section-first-page-hero">
      <slider></slider>
      <!-- <div class="new-features">
        <div
          class="container m-container-only-s"
          v-html="$t('pages.main_header')"
        ></div>
      </div> -->
    </section>
    <section class="section section-first-page-tiles">
      <div class="container m-container-only-s">
        <div class="tiles">
          <!-- <div
            class="tile full-page tile-around bottom"
            @click.prevent="pushRouteToRussia()"
          >
            <div class="tile-container">
              <p>С&nbsp;«Россией» по&nbsp;России</p>
              <span class="small">
                и&nbsp;другие города и&nbsp;регионы</span
              >
              <a href="" class="btn"><span>Подробнее</span></a>
            </div>
          </div> -->
          <div
            class="tile tile-about top"
            @click.prevent="$router.push({ name: 'Airline' })"
          >
            <div class="tile-container">
              <p class="title-pobeda" v-html="$t('pages.main_info')"></p>
              <router-link :to="{ name: 'Airline' }" class="btn"
                ><span>{{ $t("pages.main_button") }}</span></router-link
              >
            </div>
          </div>

          <div
            class="tile two-cols-right tile-entertaiments top"
            @click.prevent="$router.push({ name: 'Main' })"
          >
            <div class="tile-container">
              <p v-html="$t('pages.main_ife')"></p>
              <router-link :to="{ name: 'Main' }" class="btn"
                ><span>{{ $t("pages.main_button") }}</span></router-link
              >
            </div>
          </div>

          <div v-if="paidSection"
            class="tile two-cols-left tile-team bottom"
            @click.prevent="
              $router.push({
                name: 'MovieList',
                params: {
                  section: paidSection.id,
                  collection: paidSection.collections[0].id
                }
              })
            "
          >
            <div class="tile-container">
              <div class="money"></div>
              <p>{{ $t("pages.best_film") }}</p>
              <router-link
                class="btn"
                :to="{
                  name: 'MovieList',
                  params: {
                    section: paidSection.id,
                    collection: paidSection.collections[0].id
                  }
                }"
                >{{ $t("pages.main_button") }}</router-link
              >
            </div>
          </div>
          <div class="tile two-cols-left tile-team bottom" v-else>
						<div class="tile-container">
							<div class="money"></div>
							<p>{{ $t("pages.best_film") }}</p>
							<a href="" class="btn"><span>{{ $t("pages.main_button") }}</span></a>
						</div>
					</div>
          <div class="tile tile-press bottom"></div>
        </div>
      </div>
    </section>
    <!-- <section
      v-if="isBox"
      class="section section-branding"
      @click.prevent="pushRouteBlank('/set/partners/yamaguchi/')"
      style="cursor: pointer"
    >
      <div class="branding-container-for-img">
        <picture>
          <source
            srcset="@/assets/img/img-branding-s-new.jpg"
            media="(max-width: 767px)"
          />
          <img src="@/assets/img/img-branding-new.jpg" alt="" />
        </picture>
      </div>
    </section> -->
    <!-- <section class="section section-first-page-tiles"> -->
      <!-- <div class="container m-container-only-s"> -->
        <!-- <div class="tiles"> -->
          <!-- <div
            class="tile tile-shop middle"
            :class="{
              'disabled-tile': !isBox
            }"
            @click.prevent="$router.push({ name: 'Shop' })"
          >
            <div class="tile-container">
              <p v-html="$t('pages.main_shop')"></p>
              <router-link
                v-if="isBox && isShopOn"
                :to="{ name: 'Shop' }"
                class="btn"
                ><span>{{ $t("pages.main_button") }}</span></router-link
              >
              <span v-else class="disabled-btn">
                {{ $t("pages.main_button_disabled") }}
              </span>
            </div>
          </div> -->
        <!-- </div> -->
      <!-- </div> -->
    <!-- </section> -->
  </main>
</template>

<script>
import { mapActions, mapState } from "vuex";
import cover from "@/mixins/cover";
import partners from "@/mixins/partners";

import isFlight from "@/mixins/isFlight";
import Slider from "@/components/Slider";
export default {
  name: "IndexMain",
  components: { Slider },
  title() {
    return this.$t("pages.main");
  },
  mixins: [cover, isFlight, partners],
  data: () => {
    return {
      video: null,
      rubrics: [],
      mainRubric: ""
    };
  },
  computed: {
    ...mapState("home", {
      indexData: state => state.index,
      indexLoading: state => state.indexLoading
    }),
    ...mapState("flight", {
      flight: state => state.flight
    }),
    ...mapState("settings", {
      enabledDrm: state => state.enabledDrm
    }),
    ...mapState({
      locale: state => state.locale
    }),
    ...mapState("section", {
      indexSectionData: state => state.index,
      indexSectionLoading: state => state.indexLoading
    }),
    backgroundHeaderImage() {
      return {
        backgroundImage: `url(${require("@/assets/" +
          this.headerItems[this.step].image)})`
      };
    },
    paidSection() {
      return this.indexSectionData.filter(section => section.isPaid)[0];
    }
  },
  watch: {
    indexLoading: {
      handler() {
        if (this.indexLoading === "empty") {
          this.actionIndex();
        }
      },
      immediate: true
    }
  },
  //   next(vm => {
  //     vm.getNewJournal();
  //   });
  // },beforeRouteEnter(to, from, next) {

  methods: {
    ...mapActions("home", {
      actionIndex: "index"
    }),
    ...mapActions({
      setLocale: "SET_LOCALE"
    }),
    // pushRoute(href) {
    //   if (this.rubrics.length) {
    //     window.location.href = href;
    //   }
    // },
    // async getNewJournal() {
    //   try {
    //     const { data } = await this.axios.get(
    //       "/set/partners/srpr/rubrics.json"
    //     );
    //     this.rubrics = data.rubrics;
    //     let main = data.rubrics[0].link.split("/");
    //     main.splice(main.length - 1, 1);
    //     main.push("all");

    //     this.mainRubric = main.join("/");
    //   } catch (e) {
    //     console.log(e);
    //     this.rubrics = [];
    //   }
    // },
    pushRouteBlank(href) {
      window.open(href, "_blank");
    }
  }
};
</script>

<style scoped></style>
